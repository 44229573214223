.todo-board-title .todo-board-buttons {
  opacity: 0;
  transition: opacity 0.2s;
}

.todo-board-title .todo-board-buttons-hint {
  opacity: 1;
  transition: opacity 0.2s;
}

.todo-board-title:hover .todo-board-buttons {
  opacity: 1;
}

.todo-board-title:hover .todo-board-buttons-hint {
  opacity: 0;
}