:root {
  --black: #1F1F1F;
  --black-disabled: #7c7c7c;

  --white: white;
  --red: red;

  --success: #25A443;
  --success-hover: #25a443b0;
  --success-disabled: #58c772b0;

  --light: rgb(235, 235, 235);
  --light-hover: rgb(240, 240, 240);
  --light-disabled: rgb(247, 247, 247);

  --extra-light: rgb(240, 240, 240);

  --error-background: rgb(255, 167, 167);
  --error-border: rgb(255, 118, 118);
  --error: rgb(143, 0, 0);

  --warning: orange;
  --warning-hover: rgb(255, 187, 60);
  --warning-disabled: rgb(255, 228, 179);

  --danger: rgb(225, 78, 78);
  --danger-hover: rgb(226, 98, 98);
  --danger-disabled: rgb(247, 176, 176);

  --menu-item-hover: rgb(66, 66, 66);
  
  --padding-extra-small: 0.5rem;
  --padding-small: 1rem;
  --padding-medium: 1.5rem;
  --padding-big: 2rem;

  --margin-extra-small: 0.5rem;
  --margin-small: 1rem;
  --margin-medium: 1.5rem;
  --margin-big: 2.5rem;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
  position: relative;
}

#root {
  height: 100vh;
  display: flex;
}

.margin-bottom-small {
  margin-bottom: var(--margin-small);
}

/* Animations */

@keyframes appear-gradually {
  from {opacity: 0}
  to {opacity: 1;}
}

.appear-gradually {
  animation-name: appear-gradually;
  animation-duration: 1s;
}