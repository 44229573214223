.duration-block {
  position: relative;
  cursor: pointer;
  padding: 0.5rem 0;
  color: white;
  overflow: visible;
}

.duration-block > p {
  text-align: center;
  font-size: 0.7rem;
}

.duration-block-hover-card {
  top: 3rem;
  right: 0;
  z-index: 1;
  position: absolute;
  display: none;
  background-color: var(--white, white);
  color: var(--black, black);
  border-radius: 5px;
  padding: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.duration-block:hover > p {
  text-decoration: underline;
}

.duration-block:hover .duration-block-hover-card {
  display: inherit;
}

.duration-block:first-child .duration-block-hover-card {
  left: 0;
  right: auto;
}