.load-more-records button {
    background-color: transparent;
    font-size: 1rem;
    text-decoration: none;
    color: var(--black);
    margin: 0;
    padding: 0;
}

.load-more-records button:hover {
    text-decoration: underline;
}