tr .show-on-hover {
  opacity: 0;
  transition: opacity 0.2s;
}

tr:hover .show-on-hover {
  opacity: 1;
}

.table-row-button-entry {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

