nav {
    background-color: var(--black);
    height: 100%;
    padding: var(--padding-medium);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

nav ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style: none;
    color: white;
}

.nav-container-hidden {
    justify-content: flex-end;
}

nav .nav-collapse-button {
    display: flex;
    justify-content: flex-end;
    padding: var(--padding-extra-small);
    cursor: pointer;
}

nav .nav-collapse-button:hover {
    background-color: var(--menu-item-hover);
    border-radius: 5px;
    transition: background-color 0.2s;
}