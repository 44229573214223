.navigation-item {
  color: var(--white);
  border-radius: 5px;
  transition: background-color 0.2s;
}

.navigation-item a {
  padding: var(--padding-extra-small) var(--padding-small);
  text-decoration: none;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navigation-item:hover {
  background-color: var(--menu-item-hover)
}