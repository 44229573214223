.project-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--black);
  padding: var(--padding-extra-small);
  margin-bottom: var(--margin-small);
  position: relative;
}

.project-input .dropdown-button, .project-input .clear-button {
  cursor: pointer;
}

.project-input-dropdown {
  z-index: 10;
  position: absolute;
  background-color: var(--white);
  border: 1px solid var(--extra-light);
  top: 3rem;
  width: 100%;
  left: 0;
  overflow: visible;
}

.project-input-dropdown input {
  padding: var(--padding-extra-small);
  width: calc(100% - var(--margin-extra-small) - var(--margin-extra-small));
  margin: var(--margin-extra-small);
  border: 1px solid var(--light);
  border-radius: 5px;
}

.project-input-dropdown .project-entry-empty {
  margin: var(--margin-small);
}

.project-input-dropdown .project-entry {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding: var(--padding-extra-small);
  border-bottom: 1px solid var(--extra-light);
  cursor: pointer;
}

.project-input-dropdown .project-entry:hover {
  background-color: var(--extra-light);
}

.project-input-dropdown .project-entry:last-child {
  border-bottom: 0;
}