.todo-item-card {
  background-color: var(--light);
  padding: var(--padding-small);
  border-radius: 5px;
  max-width: 18rem;
  margin-bottom: var(--margin-medium);
}

.todo-item-card:last-child {
  margin-bottom: 0;
}

.todo-item-card .no-project {
  color: var(--black-disabled);
}

.todo-item-card .edit-todo-item {
  cursor: pointer;
  padding: 0 0.5rem;
}

.todo-item-card .edit-todo-item:hover {
  color: var(--black-disabled);
}

