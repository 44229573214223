.input-label {
    font-size: 0.7rem;
}

.input-label-error {
    color: var(--red, red);
}

.label-required-mark {
    color: var(--red, red);
    padding-left: 0.2rem;
}