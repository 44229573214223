.client-card-container {
    max-width: 60rem;;
}

.client-card-title .client-buttons {
  opacity: 0;
  transition: opacity 0.2s;
}

.client-card-title .client-buttons-hint {
  opacity: 1;
  transition: opacity 0.2s;
  cursor: pointer;
}

.client-card-title:hover .client-buttons {
  opacity: 1;
}

.client-card-title:hover .client-buttons-hint {
  opacity: 0;
}