header,
main {
    min-height: 100vh;
}

header {
    flex-shrink: 0;
}

main {
    flex: 1;
    overflow-y: scroll;
    padding: var(--padding-medium);
    background-color: rgb(247, 247, 247);
}

.main-center,
.main-flex-start,
.main-flex-end {
    display: flex;
    flex-direction: column;
}

.main-center {
    justify-content: center;
}

.main-flex-start {
    justify-content: flex-start;
}

.main-flex-end {
    justify-content: flex-end;
}