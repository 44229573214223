.text-input {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.text-input input {
    border: 0;
    border-bottom: 1px solid var(--black);
    padding: var(--padding-extra-small);
    flex-grow: 1;
}

.text-input-error input {
    border-bottom: 1px solid var(--red, red);
}

.text-input input:focus {
    outline: 0;
}

.text-input-show-password {
    cursor: pointer;
}