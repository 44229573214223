.row {
    display: flex;
    position: relative;
}


.row-margin-top {
    margin-top: var(--margin-small);
}

.row-margin-bottom {
    margin-bottom: var(--margin-small);
}

.row-margin-left {
    margin-left: var(--margin-small);
}

.row-margin-top-and-bottom {
    margin-top: var(--margin-small);
    margin-bottom: var(--margin-small);
}

.row-padding-around {
    padding: var(--padding-small);
}

.row-padding-top {
    padding-top: var(--padding-small);
}

.row-expand-margin {
    margin-left: calc(-1 * var(--padding-medium));
    margin-right: calc(-1 * var(--padding-medium));
}