.dropdown-menu-container {
  top: 2rem;
  left: -9rem;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light);
  background-color: var(--white);
  min-width: 10rem;
}

.dropdown-menu-item {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: var(--padding-extra-small);
  border-bottom: 1px solid var(--light);
  cursor: pointer;
}

.dropdown-menu-item:hover {
  background-color: var(--light-disabled);
}

.dropdown-menu-item:last-child {
  border-bottom: 0;
}