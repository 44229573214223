#home-page-container>section {
  max-width: 60rem;
  margin: 0 auto;
}

#home-page-container section h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

#home-page-container section p {
  line-height: 1.5;
}

#home-page-container section#heading {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

#heading div {
  align-self: center;
}

#heading img {
  transform: rotate(5deg);
}

#home-page-container section#clients-and-projects,
#home-page-container section#kanban {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#clients-and-projects img,
#kanban img {
  max-width: 80%;
  margin: 0 auto;
}

.feature svg {
  max-width: 10rem;
}

#home-page-container .feature>section {
  display: flex;
  max-width: 40rem;
  gap: 2rem;
  align-items: center;
}

#home-page-container #features {
  display: flex;
  flex-direction: column;
}

.feature:nth-of-type(odd) {
  align-self: flex-start;
}

.feature:nth-of-type(even) {
  align-self: flex-end;
}

#home-page-container #cta * {
  text-align: center;
}

 #home-page-container a {
  border: 0;
  padding: var(--padding-extra-small) var(--padding-small);
  border-radius: 5px;
  cursor: pointer;
  color: var(--white);
  background-color: var(--success);
  display: inline-block;
  margin-top: 2rem;
  text-decoration: none;
}

#home-page-container #cta a {
  display: block;
  max-width: 10rem;
  margin: 3rem auto;
}

@media (max-width: 1000px) {
  #home-page-container section#heading {
    grid-template-columns: 1fr;
  }

  #heading img {
    justify-self: center;
  }
}

@media (max-width: 700px) {
  #home-page-container .feature>section {
    flex-direction: column;
    gap: 1rem;
  }

  #heading img {
    max-width: 20rem;
  }

  #clients-and-projects img,
  #kanban img {
    display: none;
  }
}