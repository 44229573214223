.color-input {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    margin-bottom: var(--margin-medium);
}


.color-input-value {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s;
}

.color-input-value:hover {
    transform: scale(1.1);
}

.color-input-value.active {
    border: 3px solid black;
}