button {
  display: flex;
  align-items: center;
  border: 0;
  padding: var(--padding-extra-small) var(--padding-small);
  border-radius: 5px;
  cursor: pointer;
  color: var(--white);
  background-color: var(--success);
}

button.non-collapsible-button {
  gap: 0.5rem;
}

button:hover {
  background-color: var(--success-hover);
}

button:disabled {
  background-color: var(--success-disabled);
}

.light-button {
  color: var(--black);
  background-color: var(--light);
}

.light-button:hover {
  background-color: var(--light-hover);
}

.light-button:disabled {
  color: var(--black-disabled);
  background-color: var(--light-disabled);
}

.warning-button {
  color: var(--white);
  background-color: var(--warning);
}

.warning-button:hover {
  background-color: var(--warning-hover);
}

.warning-button:disabled {
  background-color: var(--warning-disabled);
}

.danger-button {
  color: var(--white);
  background-color: var(--danger);
}

.danger-button:hover {
  background-color: var(--danger-hover);
}

.danger-button:disabled {
  background-color: var(--danger-disabled);
}

button:hover .collapsible-button-text {
  max-width: 30rem;
  margin-left: 0.5rem;
  color: inherit;
}

.collapsible-button-text {
  white-space: nowrap;
  max-width: 0;
  overflow: hidden;
  padding: 0;
  color: transparent;
  transition: max-width 0.5s, margin-left 0.2s, color 0.1s;
}