.todo-column-danger-buttons {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s, opacity 0.2s;
  opacity: 0;
}

.todo-column:hover .todo-column-danger-buttons {
  max-height: 20rem;
  opacity: 1;
}

.todo-column .todo-column-hidden-icon {
  font-size: 0.9em;
  cursor: pointer;
  color: var(--black-disabled);
}

.todo-column .todo-column-hidden-icon:hover {
  color: var(--light);
}

.todo-column-dropdown-button {
  cursor: pointer;
}

.todo-column-dropdown-button:hover {
  color: var(--black-disabled);
}