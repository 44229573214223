table {
  margin: 0;
  padding: 0;
  border-radius: 10px;
  border-left: 1px solid var(--light);
  border-right: 1px solid var(--light);
  border-bottom: 1px solid var(--light);
  width: 100%;
  overflow: hidden;
}

table thead {
  background-color: var(--black);
  color: var(--white);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

table thead tr th {
  padding: var(--padding-extra-small);
  text-align: left;
}

table thead tr th:last-child {
  text-align: right;
}
  
  
table tbody tr td {
  padding: var(--padding-extra-small);
}

table tbody tr:nth-child(even) {
  background-color: var(--extra-light);
}

table tbody tr:nth-child(odd) {
  background-color: var(--white);
}

table tbody tr:hover {
  background-color: var(--light);
}